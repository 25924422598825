import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import URL from "../url";
import herovid from "../assets/hero.mp4";

export default function Hero() {
  const location = useLocation();
  const roomNumberRef = useRef(null);
  const roomTypeRef = useRef(null);
  const [rooms, setRooms] = React.useState([]);
  const navigate = useNavigate();
  const notyf = new Notyf({
    duration: 1000,
    position: {
      x: "center",
      y: "bottom",
    },
    types: [
      {
        type: "success",
        background: " green",
        duration: 2000,
        dismissible: true,
      },
      {
        type: "error",
        background: " rgb(27, 56, 82)",
        duration: 2000,
        dismissible: true,
      },
    ],
  });

  async function getRooms() {
    await fetch(`${URL}/api/getRooms`)
      .then((response) => response.json())
      .then((data) => {
        // Update state with the fetched rooms data
        setRooms(data.rooms);
      })
      .catch((error) => {
        console.error("Error fetching room data:", error);
      });
  }

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  React.useEffect(() => {
    getRooms();
  }, [rooms]);

  React.useEffect(() => {
    document.title = "Chariot Lodges Khanaspur";
  }, []);

  function doesObjectExist(name, availablenumber) {
    // Use the Array.prototype.some() method to check if any object in the array matches both parameters
    return rooms.some(
      (item) =>
        item.roomType == name &&
        parseInt(item.Available, 10) >= parseInt(availablenumber, 10)
    );
  }

  const checkavailable = () => {
    if (roomTypeRef.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select room type!");
    } else if (roomNumberRef.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select number of rooms!");
    } else {
      notyf.dismissAll();
      const result = doesObjectExist(
        roomTypeRef.current.value,
        roomNumberRef.current.value
      );
      if (result) {
        notyf.dismissAll();
        notyf.success("Room Available! Redirecting to booking page.");
        navigate("/contact");
        return;
      }
      notyf.dismissAll();
      notyf.error("Sorry! We are booked.");
      return;
    }
  };
  return (
    <Section id="home" className="hero__section">
      <div className="background">
        <video autoPlay loop muted>
          <source type="video/mp4" src={herovid} byte-range="0-500000" />
        </video>
      </div>
      <div className="content">
        <div className="title">
          <h3 className="huts_h3">Welcome to the Official Website of</h3>
          <h1 className="huts_h1">Chariot Lodges Khanaspur</h1>
          <p>
            Discover Serenity Amidst Majestic Mountains: Your Perfect Mountain
            Retreat
          </p>
        </div>
        <div className="search">
          {/* <div className="container">
            <label htmlFor="">Room Type</label>
            <input type="text" placeholder="Select your room type" />
          </div> */}
          <div className="container">
            <label htmlFor="">Select Room Type</label>
            <select ref={roomTypeRef} className="form-control1" required>
              <option value="" disabled selected>
                Room Type
              </option>
              <option value="Standard Room">Standard Room</option>
              <option value="Executive Room">Executive Room</option>
              <option value="Deluxe Room">Deluxe Room</option>

              {/* <option value="Adipiscing">Adipiscing</option>
										<option value="Lorem Ipsum">Lorem Ipsum</option>
										<option value="Adipiscing">Adipiscing</option> */}
            </select>
          </div>
          <div className="container">
            <label htmlFor="">Number of Rooms</label>
            <input ref={roomNumberRef} type="number" className="number-input" />
          </div>
          <button onClick={checkavailable}>Check Availability</button>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 4.7rem;
  width: 100%;
  height: 100%;
  border: 2px solid;
  .background {
    height: 100%;

    video {
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      width: 100%;
      filter: brightness(50%);
    }
  }
  .form-control1 {
    width: 100%;
    font-weight: 300;
    color: black;
    font-size: 14px;
    letter-spacing: 1.2px;
    padding: 10px 10px;
    outline: none;
    background: rgba(191, 149, 149, 0.08);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.27);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }
  .number-input {
    width: 100%;
    font-weight: 300;
    color: black;
    font-size: 14px;
    letter-spacing: 1.2px;
    padding: 10px 10px;
    outline: none;
    background: rgba(191, 149, 149, 0.06);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.27);
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
  }

  .huts_h1 {
    color: #ffffffce;
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          text-align: center;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 0.2rem;
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      background-color: palegreen;
      video {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1.6rem;
        }
        h3 {
          font-size: 0.7rem;
        }
        p {
          font-size: 0.9rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
