import React, { useState } from "react";
import images from "../Gallery/images";
import "./gallery.css";

const Gallery = () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  React.useEffect(() => {
    document.title = "Gallery - Chariot Lodges";
  }, []);
 



  const chunkSize = 5; // Number of images per chunk
  const imageChunks = [];

  for (let i = 0; i < images.length; i += chunkSize) {
    const chunk = images.slice(i, i + chunkSize);
    imageChunks.push(chunk);
  }

  return (
    <section className="gallery__section" id="gallery">
      <div className="container1">
        <div className="heading1">
          <h3>
            Photo <span>Gallery</span>
          </h3>
        </div>

        <div className="gallery main__gallery">
          {imageChunks.map((chunk, index) => (
            <div className="gallery__col" key={index}>
              {chunk.map((image, i) => (
                <img
                  className="gallery__img"
                  key={i}
                  src={image.path}
                  alt={`Image ${image.id}`}
                  // onClick={() => openLightbox(image.id)}
                />
              ))}
            </div>
          ))}
          {/* {isLightboxOpen && (
            <PhotoLightbox
              isOpen={isLightboxOpen}
              closeLightbox={closeLightbox}
              imageArray={images}
              selectedImageId={selectedImageId}
            />
          )} */}
        </div>

        {/* <WSPGallery galleryImages={images} /> */}
      </div>
    </section>
  );
};

export default Gallery;
