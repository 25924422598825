import React, { useState } from "react";
import logo from "../assets/logo.png";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
export default function Navbar() {
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".headern");
    if (this.scrollY >= 80) header.classList.add("scroll-header");
    else header.classList.remove("scroll-header");
  });

  const [activeNav, setActiveNav] = useState("#home");
  const [Toggle, showMenu] = useState(false);
  const navigate = useNavigate();
  return (
    <header className="headern">
      <nav className="navn containern">
        <a href="javascript:;" className="nav__logo">
          <img
            src={logo}
            onClick={() => {
              navigate("/");
              window.scrollTo({
                top: 0,
                behavior: "smooth", // Use 'auto' for instant scroll
              });
            }}
            className="header__logo"
            alt="logo"
          />
        </a>
        <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list gridn">
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#home");
                  navigate("/");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Use 'auto' for instant scroll
                  });
                }}
                className={
                  activeNav === "#home" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-estate nav__icon"></i>
                Home{" "}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#about");
                  navigate("/#about");
                }}
                className={
                  activeNav === "#about" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-user nav__icon"></i>
                About{" "}
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#rooms");
                  navigate("/#rooms");
                }}
                className={
                  activeNav === "#rooms" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-building nav__icon"></i>
                Rooms
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#services");
                  navigate("/#services");
                }}
                className={
                  activeNav === "#services"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-server nav__icon"></i>
                Services
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#activity");
                  navigate("/#activity");
                }}
                className={
                  activeNav === "#activity"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-play nav__icon"></i>
                Activities
              </a>
            </li>

            {/* <li className="nav__item">
              <a
                href="#services"
                onClick={() => setActiveNav("#services")}
                className={
                  activeNav === "#services"
                    ? "nav__link active-link"
                    : "nav__link"
                }
              >
                <i className="uil uil-briefcase-alt nav__icon"></i>
                Services
              </a>
            </li> */}
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#gallery");
                  navigate("/gallery");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Use 'auto' for instant scroll
                  });
                }}
                className={
                  activeNav === "#rooms" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-image nav__icon"></i>
                Gallery
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#map");
                  navigate("/map");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Use 'auto' for instant scroll
                  });
                }}
                className={
                  activeNav === "#rooms" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-image nav__icon"></i>
                Map
              </a>
            </li>
            <li className="nav__item">
              <a
                href="javascript:;"
                onClick={() => {
                  setActiveNav("#contact");
                  navigate("/contact");
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Use 'auto' for instant scroll
                  });
                }}
                className={
                  activeNav === "#rooms" ? "nav__link active-link" : "nav__link"
                }
              >
                <i className="uil uil-check-circle nav__icon"></i>
                Book-Now
              </a>
            </li>
          </ul>

          <i
            class="uil uil-times nav__close"
            onClick={() => showMenu(!Toggle)}
          ></i>
        </div>
        <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
}
