import React, { useEffect } from "react";
import styled from "styled-components";

import image1 from "../assets/mushkpuri.jpg";
import image2 from "../assets/Chairlift.jpg";
import image3 from "../assets/anp.jpg";
import image4 from "../assets/lwp.jpg";
import image5 from "../assets/amt.png";
import image6 from "../assets/vmc.jpg";
import { useLocation } from "react-router-dom";

export default function Testimonials() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Section id="activity">
      <div className="title">
        <h2 className="text">ATTRACTIONS AND ACTIVITIES</h2>
      </div>
      <div class="cards">
        <div class="card card1">
          <div class="container">
            <img src={image1} alt="las vegas" />
          </div>
          <div class="details">
            <h3>Mushkpuri</h3>
            <p>
              Mukshpuri, Mushkpuri, or Mukeshpuri[dubious – discuss]is a
              2,800-metre-high (9,200 ft) mountain in the Nathia Gali Hills. It
              is the second highest peak in the Galyat Region after Miranjani
              which is located at 2,992 metres (9,816 ft).&nbsp;
              <a
                className="see_more"
                href="https://en.wikipedia.org/wiki/Mukeshpuri"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
        <div class="card card2">
          <div class="container">
            <img src={image2} alt="New York" />
          </div>
          <div class="details">
            <h3>Ayubia Chair Lift</h3>
            <p>
              Ayubia chair lift is one of the most famous and ancient chair
              lifts in the east corner of Khyber Pakhtunkhwa province of
              Pakistan. The height of Ayubia’s chair lift is 8000 feet above sea
              level. The entire ride of the Ayubia chair lift is about 7
              kilometers.&nbsp;
              <a
                className="see_more"
                href="https://gda.gkp.pk/product/ayubia-chairlift/"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
        <div class="card card3">
          <div class="container">
            <img src={image3} alt="Singapore" />
          </div>
          <div class="details">
            <h3>Ayubia National Park</h3>
            <p>
              Ayubia National Park spans 3,312 hectares in Abbottabad District,
              Pakistan. Surrounded by Thandiani, Nathiagali, and Khanspur, it's
              a protected area of natural beauty. Explore this serene park in
              Khyber Pakhtunkhwa province.&nbsp;
              <a
                className="see_more"
                href="https://en.wikipedia.org/wiki/Ayubia_National_Park"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
        <div class="card card1">
          <div class="container">
            <img src={image4} alt="las vegas" />
          </div>
          <div class="details">
            <h3>Lalazar Safari Park</h3>
            <p>
              Lalazar Safari Park is a park located on the Lalazar mountain top
              at a height of 9,000 feet in Nathiagali, Murree. The Lalazar Fauna
              Park is a focal place where visitors may best examine the area's
              famous wildlife while taking a leisurely walk.&nbsp;
              <a
                className="see_more"
                href="https://en.wikipedia.org/wiki/Lalazar_Safari_Park"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
        <div class="card card2">
          <div class="container">
            <img src={image5} alt="New York" />
          </div>
          <div class="details">
            <h3>Ayubia Motto Tunnel</h3>
            <p>
              The Dunga Gali pipeline track spans 3.5 kilometers from Donga Gali
              to Ayubia. With stunning scenery and abundant wildlife, it offers
              a serene experience. Spot various bird species like Yellow Beaked
              Magpie, Red Blood and Yellow Body Finches, and White Cheek or
              Crested Grey Finch.&nbsp;
              <a
                className="see_more"
                href="https://gulfnews.com/world/asia/pakistan/from-trash-to-treasure-129-year-old-british-era-tunnel-restored-in-pakistan-1.74936821"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
        <div class="card card3">
          <div class="container">
            <img src={image6} alt="Singapore" />
          </div>
          <div class="details">
            <h3>Virgin Mary Catholic Church</h3>
            <p>
              Virgin Mary Catholic Church, a colonial relic, stands on
              Khanspur's hillside. Built in 1911, it echoes the region's
              history. Linked to St. Matthews Church in Nathiagali, it holds
              significance in Pakistan's past.&nbsp;
              <a
                className="see_more"
                href="https://www.youtube.com/watch?v=Ph_PS6_yl-4"
                target="_blank"
              >
                See more.
              </a>
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  margin: 5rem 0;
  margin: 1rem 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .see_more {
    color: #333;
    font-weight: 900;
    transition: all 0.3s ease;
  }
  .see_more:hover {
    text-decoration: underline;
    color: red;
  }
  .text {
    text-transform: uppercase;
    background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.6vw;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
    padding: 40px;
    grid-gap: 40px;
  }
  .card {
    background-color: aliceblue;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    border-radius: 20px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.18);
    transition: transform 0.3s;
  }
  .card:hover {
    transform: translateY(-5px);
  }
  .container {
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  }
  img {
    width: 100%;
    display: block;
    border-radius: 20px 20px 0 0;
  }
  .container:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    opacity: 0.7;
  }

  .details {
    padding: 20px 10px;
  }
  .details > h3 {
    color: black;
    font-weight: 600;
    font-size: 18px;
    margin: 10px 0 15px 0;
  }
  .details > p {
    color: #black;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
  }

  @media only screen and (min-width: 650px) and (max-width: 1000px) {
    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30vw, 1fr));
      padding: 20px;
      grid-gap: 40px;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 768px) {
    .text {
      font-size: 5.3vw;
    }
  }
  @media only screen and (max-width: 480px) {
    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
      padding: 10px;
      grid-gap: 40px;
    }
  }
  @media only screen and (min-width: 480px) and (max-width: 650px) {
    .cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(50vw, 1fr));
      padding: 20px;
      grid-gap: 40px;
    }
  }
`;
