import React, { useRef, useState } from "react";
import "./Modal.css";
import close1 from "../assets/close.png";
import { notyf } from "./notyf";
import URL from "../url";
import testimonialimage from "../assets/testimonialimage.jpg";
function Modal({ setOpenModal, Testimonial }) {
  const nameRef = useRef(null);
  const messageRef = useRef(null);
  const [imageData, setImageData] = useState("");

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 200 * 1024) {
        notyf.error("Image size exceeds 500KB");
        e.target.value = "";
        return;
      } else {
        setImageData(selectedFile);
      }
    }
  };

  const convertToBase64 = async (imageFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(imageFile);
    });
  };

  const addTestimonial = async (e) => {
    e.preventDefault();

    if (nameRef.current.value.length <= 2) {
      notyf.dismissAll();
      notyf.error("Enter a valid name");
      return;
    }
    if (messageRef.current.value === "") {
      notyf.dismissAll();
      notyf.error("Please write a review");
      return;
    }
    let base64 = "";
    if (imageData !== "") {
      base64 = await convertToBase64(imageData);
    } else {
      base64 =
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQACWAJYAAD/2wCEAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDIBCQkJDAsMGA0NGDIhHCEyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMv/CABEIAMgAyAMBIgACEQEDEQH/xAAwAAEBAAMBAQEAAAAAAAAAAAAABQIDBAEGBwEBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAA/XxrmAAAAAAAAAAAAAAAAAdnVNSVnAkuzjsBAAAAAAAAMq2PVnYKA5+gQca0m4CwAAAAABu090tITYAACLam2cIuAAAAAAHfwdUtYTYAACdRkpyjWAAAAAAHvhbmyPXzr0KAPDCJv57gLAAAAAAAG7SWx0fP5y3UTArTdJAsAAAAAAAMu6WfurbGpeygODygiXptK+fXOJOBljcgAAAAOn2rNY5k0AAAABhLr+JAdXLrIIAA2a6UvZkTYAAAAAAGMW5w2TRcAALMa5NbBNAAAAAAANO7WQxrAIAuQ7k3sEoAAAAAADXs1kMawCALhN7BKAAAAAAA1hDGsAn/xAA3EAACAQEDCAkCBQUAAAAAAAABAgMEABExBRIgITBAQVETIjI0YXFyobFTkRQjYoGCEBVSYNH/2gAIAQEAAT8A/wBUAJNwBJ5C0WT3cXyHMHLE2SggXFS3mbfhIPpLZ8nwNgCp8DaXJ8iAmM545YGxBBuIuPLco42kcIovJtT0qQLfi/FtKopUnXk/BrSI0blHFxG40VP0MWcw67e2wrafpYs5R111+Y3Clj6WoVTgNZ2VVH0VQwGB1jb5NX8yRuQA2WUl/MjbmCNvkw65B5bLKR1xjwO3oHzKi44MLtlXvn1N3+Iu26sVYMMQbxaGQSxK44+2wmlEMTOeGHjYksxY4k3ncKSpMD3G8ocfDxsrB1DKQQcCNJmCqWY3AYm1XUmd7hqQYblBUyQHVrXiptFWRS6s7Nbk39cLS1kMV4zs5uS2nqZJzrNy8FG6rLInZdh5G34qf6rfezSSP2nY+Z3TE3WSlnfCMgczqsuTpD2nUe9hk3nL9lt/bV+q32scmDhL91s2TpB2XU+1npZ0xjJHMa7YG47dEeRs1FLHwtDk7jK38RaOGOIdRAP22EkEco66A+N1pcncYm/i3/bOjRtmupB8dpTUjTdZtSc+do4kiXNQADaSRJKua4vFqmkaDrL1k58tlR0vTHPfsD3sAALhtiARccLVlL0Jz07B9thDEZpVQfueQsiBFCqLgBcNwdA6lWF4Oo2miMMrIeGB5jTydEAjSHEm4eW5ZRjvRZBiNR8tOlXNpox+m/cqtc6lkH6b9ODu8fpHxuU/d5PSdODu8fpHxuU/d5PSfjTg7vH6R8blP3eT0n404O7x+kfG5T93k9J+ND//xAAZEQADAQEBAAAAAAAAAAAAAAABETAAIED/2gAIAQIBAT8A8Tz9gqKiSyyk8888+3QxHBqamn//xAAbEQEAAwEAAwAAAAAAAAAAAAABESAwABIxQP/aAAgBAwEBPwD4o6Mwo4FXAq+sRouQ95cuUdB0HR0XC6TUxaGpqaf/2Q==";
    }

    const name = nameRef.current.value;
    const message = messageRef.current.value;

    try {
      const response = await fetch(`${URL}/addTestimonial`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          arg1: name,
          arg2: base64,
          arg3: message,
        }),
      });

      if (response.ok) {
        const updatedObject = await response.json();
        notyf.dismissAll();
        notyf.success("Added Successfully!");
      } else {
        notyf.dismissAll();
        notyf.error("Failed to add, Try again");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setOpenModal(false);
    Testimonial([]);
  };

  return (
    <>
      <div className="modal">
        <form className="form">
          <img
            onClick={() => setOpenModal(false)}
            className="close-button"
            src={close1}
          />
          <span className="form-title">Add your review</span>
          <input
            ref={nameRef}
            className="form-text"
            type="text"
            placeholder="Your Name"
          />
          <textarea
            ref={messageRef}
            className="form-textarea"
            placeholder="Your message/review"
          />
          <label htmlFor="file-input" className="drop-container">
            <p className="form-paragraph">
              File should be an image
              <br /> Maximum 200kb
            </p>
            <input
              onChange={handleImageChange}
              type="file"
              accept="image/*"
              required=""
              id="file-input"
            />
          </label>
          <button
            onClick={(e) => addTestimonial(e)}
            type="button"
            className="submit-Button"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default Modal;
