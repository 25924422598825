import React from "react";
import "./testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import add1 from "../assets/add.png";
import Modal from "./Modal";
import URL from "../url";


function Testimonial() {
  const [testimonial, setTestimonial] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  async function getTestimonial() {
    await fetch(`${URL}/api/testimonial`)
      .then((response) => response.json())
      .then((data) => {
        setTestimonial(data.testimonial.reverse());
      })
      .catch((error) => {
        console.error("Error fetching testimonial data:", error);
      });
  }

  React.useEffect(() => {
    getTestimonial();
  }, [testimonial]);

  return (
    <>
      {modalOpen ? (
        <Modal setOpenModal={setModalOpen} Testimonial={setTestimonial} />
      ) : (
        <section className="testimonialt containert sectiont">
          <div className="title1">
            <h1 className="text1">TESTIMONIALS</h1>
          </div>
          <div className="add-div" onClick={() => setModalOpen(true)}>
            <img className="add-new" src={add1} alt="add-button" />
            <p className="add-text">&nbsp;&nbsp;Add new</p>
          </div>

          <Swiper
            className="testimonials__container"
            loop={true}
            grabCursor={true}
            spaceBetween={24}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              576: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 48,
              },
            }}
            modules={[Pagination]}
          >
            {testimonial.map((data) => {
              return (
                <SwiperSlide className="testimonials__card" key={data._id}>
                  <img src={data.image} alt="" className="testimonials__img" />
                  <h3 className="testimonials__name">{data.Name}</h3>
                  <p className="testimonials__description">{data.message}</p>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      )}
    </>
  );
}

export default Testimonial;
