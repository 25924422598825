import React, { Fragment, useEffect, useState } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Recommend from "./components/Recommend";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import scrollreveal from "scrollreveal";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import About from "./components/About";
import Testimonial from "./components/Testimonial";
import Map from "./components/Map";
import NotFound from "./components/NotFound";

export default function App() {
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  useEffect(() => {
    if (!isLoading) {
      const sr = scrollreveal({
        origin: "top",
        distance: "70px",
        duration: 1000,
        reset: false,
      });
      sr.reveal(
        `
        nav,
        #hero,
        #rooms,
        #services,
        #activity
        
        footer
        
        `,
        {
          opacity: 0,
          interval: 300,
        }
      );
    }
  }, [isLoading]);
  if (isLoading) {
    return null;
  }
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Fragment>
                <Hero />
                <About />
                <Recommend />
                <Services />
                <Testimonials />
                <Testimonial />
              </Fragment>
            }
          />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/map" element={<Map />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
