import React, { useEffect } from "react";
import styled from "styled-components";
import service1 from "../assets/mountain.png";
import service2 from "../assets/accom.png";
import service3 from "../assets/cuisine.png";
import service4 from "../assets/safety.png";
import service5 from "../assets/parking.png";
import service6 from "../assets/wifi.png";
import service7 from "../assets/breakfast.png";
import service8 from "../assets/iron.png";
import "./services.css";
import { useLocation } from "react-router-dom";
export default function Services() {
  const data = [
    {
      icon: service1,
      title: "Serene Mountain Views",
      subTitle:
        " Immerse yourself in the tranquility of nature as our hotel offers breathtaking vistas of majestic mountains and picturesque landscapes.",
    },
    {
      icon: service2,
      title: "Cozy Accommodation",
      subTitle:
        "Enjoy comfortable huts and chalets nestled in the heart of the mountains, offering tranquility and stunning views.",
    },
    {
      icon: service3,
      title: "Gourmet Cuisine",
      subTitle:
        " Savor delicious, locally sourced meals prepared by expert chefs, featuring a fusion of regional and international flavors.",
    },
    {
      icon: service4,
      title: "Safety and Security",
      subTitle:
        "Ensuring your well-being is our top priority with trained staff, emergency protocols, and modern equipment to provide a secure environment throughout your stay.",
    },
    {
      icon: service5,
      title: "Ample Parking Space",
      subTitle:
        "We offer the convenience of ample parking, allowing you to easily find a spot for your car, so you can focus on enjoying your stay without worrying about parking hassles",
    },
    {
      icon: service6,
      title: "Fast Internet Connection (Complimentary)",
      subTitle:
        "Stay connected with high-speed complimentary Wi-Fi during your stay, ensuring you're always in touch, whether for business or leisure.",
    },
    {
      icon: service7,
      title: "Breakfast (Complimentary)",
      subTitle:
        " Start your day right with our indulgent complimentary breakfast, featuring a delectable spread of both continental and local favorites",
    },
    {
      icon: service8,
      title: "Ironing (Complimentary)",
      subTitle:
        "Enjoy a hassle-free stay with our complimentary ironing service, ensuring your outfits are crisp and ready for any occasion.",
    },
  ];

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="title1">
        <h1 className="text1">SERVICES</h1>
      </div>
      <Section id="services">
        {data.map((service, index) => {
          return (
            <div className="service">
              <div className="icon">
                <img src={service.icon} alt="" />
              </div>
              <h3 className="title-service">{service.title}</h3>
              <p>{service.subTitle}</p>
            </div>
          );
        })}
      </Section>
    </>
  );
}

const Section = styled.section`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 1rem 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  .service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.3rem) translateY(-0.3rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      img {
        height: 2.4rem;
      }
    }
  }
  .title-service {
    font-weight: bold;
    font-size: 1.3rem;
  }

  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
