const images = [
  {
    id: 1,
    path: require("../assets1/1.jpg"),
  },
  {
    id: 2,
    path: require("../assets1/2.jpg") ,
  },
  {
    id: 3,
    path: require("../assets1/3.jpg") ,
  },
  {
    id: 4,
    path: require("../assets1/4.jpg") ,
  },
  {
    id: 5,
    path: require("../assets1/5.jpg") ,
  },
  {
    id: 6,
    path: require("../assets1/6.jpg") ,
  },
  {
    id: 7,
    path: require("../assets1/7.jpg") ,
  },
  {
    id: 8,
    path: require("../assets1/8.jpg") ,
  },
  {
    id: 9,
    path: require("../assets1/9.jpg") ,
  },
  {
    id: 10,
    path: require("../assets1/10.jpg") ,
  },
  {
    id: 11,
    path: require("../assets1/11.jpg") ,
  },
  {
    id: 12,
    path: require("../assets1/12.jpg") ,
  },
  {
    id: 13,
    path: require("../assets1/13.jpg") ,
  },
  {
    id: 14,
    path: require("../assets1/14.jpg") ,
  },
  {
    id: 15,
    path: require("../assets1/15.jpg") ,
  },
  {
    id: 16,
    path: require("../assets1/16.jpg") ,
  },
  {
    id: 17,
    path: require("../assets1/17.jpg") ,
  },
  {
    id: 18,
    path: require("../assets1/18.jpg") ,
  },
  {
    id: 19,
    path: require("../assets1/19.jpg") ,
  },
  {
    id: 20,
    path: require("../assets1/20.jpg") ,
  },
  {
    id: 21,
    path: require("../assets1/21.jpg") ,
  },
  {
    id: 22,
    path: require("../assets1/22.jpg") ,
  },
  {
    id: 23,
    path: require("../assets1/23.jpg") ,
  },
  {
    id: 24,
    path: require("../assets1/24.jpg") ,
  },
  {
    id: 25,
    path: require("../assets1/25.jpg") ,
  },
  {
    id: 26,
    path: require("../assets1/26.jpg") ,
  },
  {
    id: 27,
    path: require("../assets1/27.jpg") ,
  },
  {
    id: 28,
    path: require("../assets1/28.jpg") ,
  },
  {
    id: 29,
    path: require("../assets1/29.jpg") ,
  },
  {
    id: 30,
    path: require("../assets1/30.jpg") ,
  },
  {
    id: 31,
    path: require("../assets1/31.jpg") ,
  },
  {
    id: 32,
    path: require("../assets1/32.jpg") ,
  },
  {
    id: 33,
    path: require("../assets1/33.jpg") ,
  },
  {
    id: 34,
    path: require("../assets1/34.jpg") ,
  },
  {
    id: 35,
    path: require("../assets1/35.jpg") ,
  },
  {
    id: 36,
    path: require("../assets1/36.jpg") ,
  },
  {
    id: 37,
    path: require("../assets1/37.jpg") ,
  },
  {
    id: 38,
    path: require("../assets1/38.jpg") ,
  },
  {
    id: 39,
    path: require("../assets1/39.jpg") ,
  },
  {
    id: 40,
    path: require("../assets1/40.jpg") ,
  },
  {
    id: 41,
    path: require("../assets1/41.jpg") ,
  },
  {
    id: 42,
    path: require("../assets1/42.jpg") ,
  },
  {
    id: 43,
    path: require("../assets1/43.jpg") ,
  },
  {
    id: 44,
    path: require("../assets1/44.jpg") ,
  },
  {
    id: 45,
    path: require("../assets1/45.jpg") ,
  },
  {
    id: 46,
    path: require("../assets1/46.jpg") ,
  },
  {
    id: 47,
    path: require("../assets1/47.jpg") ,
  },
  {
    id: 48,
    path: require("../assets1/48.jpg") ,
  },
  {
    id: 49,
    path: require("../assets1/49.jpg") ,
  },
  {
    id: 50,
    path: require("../assets1/50.jpg") ,
  },
  {
    id: 51,
    path: require("../assets1/51.jpg") ,
  },
  {
    id: 52,
    path: require("../assets1/52.jpg") ,
  },
  {
    id: 53,
    path: require("../assets1/53.jpg") ,
  },
  {
    id: 54,
    path: require("../assets1/54.jpg") ,
  },
  {
    id: 55,
    path: require("../assets1/55.jpg") ,
  },
  {
    id: 56,
    path: require("../assets1/56.jpg") ,
  },
  {
    id: 57,
    path: require("../assets1/57.jpg") ,
  },
  {
    id: 58,
    path: require("../assets1/58.jpg") ,
  },
  {
    id: 59,
    path: require("../assets1/59.jpg") ,
  },
  {
    id: 60,
    path: require("../assets1/60.jpg") ,
  },
];
export default images;
