import React from "react";
import "./map.css";

import { Notyf } from "notyf";
import "notyf/notyf.min.css";
function Map() {
  const notyf = new Notyf({
    duration: 1000,
    position: {
      x: "center",
      y: "bottom",
    },
    types: [
      {
        type: "success",
        background: " green",
        duration: 2000,
        dismissible: true,
      },
      {
        type: "error",
        background: " rgb(27, 56, 82)",
        duration: 2000,
        dismissible: true,
      },
    ],
  });

  React.useEffect(() => {
    document.title = "Map - CHARIOT LODGES";
  }, []);

  const copyLocation = () => {
    window.open(
      "https://www.google.com/maps/place/Armour+Hut/@34.0198782,73.4238889,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfd4510323f7cb:0xed5fc2d8d891c988!8m2!3d34.0198782!4d73.4238889!16s%2Fg%2F11hz_kygyz?hl=en-PK&entry=ttu",
      "_blank"
    );
  };
  return (
    <section className="" id="map">
      <div className="data_div">
        <p>
          The below map contains route to Chariot Lodges from mall road murre
          and islamabad motorway toll plaza for your convenience. The yellow
          mark represents Chariot Lodges location.
        </p>

        <button className="button" onClick={() => copyLocation()}>
          <p className="text">Open Location</p>
        </button>
      </div>
      <div className="map_div">
        <iframe
          src="https://www.google.com.qa/maps/d/u/0/embed?mid=1te5qVCfeMK6NSL0UsqCdzuGcT5XPipA&ehbc=2E312F"
          className="iframe_map"
        ></iframe>
      </div>
    </section>
  );
}

export default Map;
