import React, { useEffect, useState } from "react";
import styled from "styled-components";
import data from "../Gallery/Roomsdata";
import URL from "../url";
import { useLocation, useNavigate } from "react-router-dom";
import "./Recommend.css";

export default function Recommend() {
  const navigate = useNavigate();
  const [rooms, setRooms] = useState([]);
  const [discountArray, setDiscountArray] = useState([]);
  async function getRooms() {
    await fetch(`${URL}/api/getRooms`)
      .then((response) => response.json())
      .then((data) => {
        // Update state with the fetched rooms data
        setRooms(data.rooms);
      })
      .catch((error) => {
        console.error("Error fetching room data:", error);
      });
  }
  React.useEffect(() => {
    getRooms();
    setDiscountArray(rooms.map((item) => item.Discount));
  }, [rooms]);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1);
      scrollToSection(sectionId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Section id="rooms" className="rooms">
      <div className="title">
        <h1 className="text">ROOMS</h1>
      </div>
      <div className="destinations">
        {data.map((destination) => {
          return (
            <div className="destination" key={destination.id}>
              <div className="image-container">
                {/* src={destination.images[currentIndex]} */}
                <video autoPlay loop muted>
                  <source type="video/mp4" src={destination.video} />
                </video>
                {discountArray[destination.id - 1] != "0%" &&
                  discountArray[destination.id - 1] != "0% off" && (
                    <div class="discount-tag">
                      <p className="discount-p">
                        {discountArray[destination.id - 1]} &nbsp;off
                      </p>
                    </div>
                  )}
              </div>
              <strong>
                <h3>{destination.title}</h3>
              </strong>
              <p>{destination.subTitle}</p>
              <div className="info">
                {/* <div className="services">
                  <img src={info2} alt="" />
                  <img src={info3} alt="" />
                </div> */}
                {/* <h4>{destination.cost}</h4> */}
              </div>
              <div className="price-div">
                {rooms && discountArray[destination.id - 1] == "0%" && (
                  <div class="actual-tag">
                    <p className="actual-price">
                      Room Fare: <span>{rooms[destination.id - 1]?.Fare}</span>
                      &nbsp; PKR/-
                    </p>
                  </div>
                )}
                {rooms && discountArray[destination.id - 1] != "0%" && (
                  <div class="discounted-tag">
                    <p className="actual-price-discounted">
                      Room Fare: &nbsp;
                      <span className="discounted-actual">
                        {rooms[destination.id - 1]?.Fare}
                      </span>
                      &nbsp; PKR/-
                    </p>
                    <p className="discounted-price">
                      Discounted Price:{" "}
                      <span>
                        {Math.round(
                          parseInt(rooms[destination.id - 1]?.Fare, 10) -
                            parseInt(rooms[destination.id - 1]?.Fare, 10) *
                              (parseInt(discountArray[destination.id - 1]) /
                                100)
                        )}
                      </span>
                      &nbsp; PKR/-
                    </p>
                  </div>
                )}
              </div>

              <div className="distance">
                <button
                  className="book-now"
                  onClick={() => {
                    navigate("/contact");
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth", // Use 'auto' for instant scroll
                    });
                  }}
                >
                  <span className="book-now-span">Book Now</span>
                </button>
                <button
                  className="book-now"
                  onClick={() => {
                    const whatsappLink = `https://wa.me/+923141979327`;
                    // Open WhatsApp using the constructed link
                    window.open(whatsappLink, "_blank");
                  }}
                >
                  <span className="book-now-span">Whatsapp</span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  margin: 1rem 2rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  .title {
    margin-bottom: 1.5rem;
    text-align: center;
    margin-top: -3rem;
  }
  .image-container {
    position: relative;
    display: inline-block;
  }

  .discount-p {
    font-size: 0.85rem;
  }
  .discount-tag {
    position: absolute;

    top: -1rem; /* Adjust the top distance as needed */
    right: -1rem; /* Adjust the right distance as needed */
    background-color: #ff0000; /* Background color of the discount tag */
    color: #fff; /* Text color of the discount tag */
    padding: 3px 5px; /* Adjust the padding as needed */
    font-weight: bold;
    border-radius: 5px; /* Add rounded corners if desired */
    // transform: rotate(45deg); /* Rotate the discount tag */
    transform-origin: right bottom; /* Set the rotation origin */
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  }
  .text {
    text-transform: uppercase;
    background: linear-gradient(to right, #30cfd0 0%, #330867 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3.6vw;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #8338ec;
      }
    }
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: aliceblue;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      overflow: hidden;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: fadeImages 5s ease-in-out infinite;
      }
      @keyframes fadeImages {
        0% {
          opacity: 0.5;
        }
        20% {
          opacity: 1;
        }
        80% {
          opacity: 1;
        }
        100% {
          opacity: 0.5;
        }
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            background-color: #4d2ddb84;
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .text {
      margin-top: 3rem;
      font-size: 7.3vw;
    }
  }
`;
