import React from "react";
import "./footer.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import contactbook from "../assets/contact-book.png";
import terms from "../assets/terms.png";

function Footer() {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer__container containerf">
        {/* <h1 className="footer__title">Chariot Lodges</h1> */}
        <img
          src={logo}
          onClick={() => {
            navigate("/");
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Use 'auto' for instant scroll
            });
          }}
          className="header__logof"
          alt="logo"
        />
        <ul className="footer__list">
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Use 'auto' for instant scroll
                });
              }}
              className="footer__link"
            >
              Home
            </a>
          </li>

          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/#about");
              }}
              className="footer__link"
            >
              About{" "}
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/#rooms");
              }}
              className="footer__link"
            >
              Rooms
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/#services");
              }}
              className="footer__link"
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/#activity");
              }}
              className="footer__link"
            >
              Activities
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/gallery");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Use 'auto' for instant scroll
                });
              }}
              className="footer__link"
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/map");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Use 'auto' for instant scroll
                });
              }}
              className="footer__link"
            >
              Map
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              onClick={() => {
                navigate("/contact");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Use 'auto' for instant scroll
                });
              }}
              className="footer__link"
            >
              Book-Now
            </a>
          </li>
        </ul>
        <div className="footer__social">
          <div className="terms">
            <div className="heading-div">
              <img src={terms} alt="contact" className="terms-image" />
              <h2 className="terms-heading">&nbsp; Terms and Conditions</h2>
            </div>
            <p className="terms-desc">
              <strong>·&nbsp;</strong>First come, first serve basis
            </p>
            <p className="terms-desc">
              <strong>·&nbsp;</strong>50% adv for confirmation of booking
            </p>
            <p className="terms-desc">
              <strong>·&nbsp;</strong>Extension of rooms offered on aval
            </p>
            <p className="terms-desc">
              <strong>·&nbsp;</strong>Advance is non refundable in case of
              cancellation less than 3 x days
            </p>
          </div>
          <div className="contact">
            <div className="heading-div">
              <img src={contactbook} alt="contact" className="terms-image" />
              <h2 className="contact-heading">&nbsp;Contact</h2>
            </div>
            <p className="phone-number">
              <strong>·&nbsp;</strong>
              <strong>Land Line</strong> - 0992-359139
            </p>
            <p className="phone-number">
              <strong>·&nbsp;</strong>
              <strong>Booking Manager</strong> - 0319-7509925
            </p>
            <p className="phone-number">
              <strong>·&nbsp;</strong> <strong>Hut Staff</strong> - 0343-5083353
            </p>

            <p className="phone-number">
              <strong>·&nbsp;</strong> <strong>Booking Staff</strong> -
              0314-1979327
            </p>
            <p className="phone-number">
              <strong>·&nbsp;</strong> <strong>Email</strong> -
              chariotlodges@gmail.com
            </p>
          </div>
        </div>

        <span className="footer__copy">&#169; Chariot Lodges 2024</span>
      </div>
    </footer>
  );
}

export default Footer;

{
  /* <a
            href="https://github.com/haseeeb1"
            className="home__social-icon"
            target="_blank"
          >
            <i className="uil uil-facebook"></i>
          </a>
          <a
            href="https://linkedin.com/in/haseeb-ul-hassan"
            className="home__social-icon"
            target="_blank"
          >
            <i className="uil uil-instagram"></i>
          </a>
          <a
            href="https://instagram.com/haseeeb_ul_hassan"
            className="home__social-icon"
            target="_blank"
          >
            <i className="uil uil-youtube"></i>
          </a> */
}
