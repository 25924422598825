import { Notyf } from "notyf";
import "notyf/notyf.min.css";

export const notyf = new Notyf({
  duration: 1000,
  position: {
    x: "center",
    y: "top",
  },
  types: [
    {
      type: "success",
      background: " green",
      duration: 3000,
      dismissible: true,
    },
    {
      type: "error",
      background: " rgb(27, 56, 82)",
      duration: 3000,
      dismissible: true,
    },
  ],
});
