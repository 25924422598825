import URL from "../url";
import deluxeac from "../assets/deluxeac.mp4";
import executiveac from "../assets/executiveac.mp4";
import standardac from "../assets/standardac.mp4";

const data = [
  {
    id: 1,

    title: "Standard Rooms",
    subTitle:
      "Comfortable and affordable accommodations for a delightful stay.",
    cost: "7000",
    duration: "Approx 2 night trip",

    video: standardac,
  },
  {
    id: 2,

    title: "Executive Rooms",
    subTitle:
      "Unparalleled luxury and sophistication for the discerning traveler.",
    cost: "10000",
    duration: "Approx 2 night trip",
    video: executiveac,
  },

  {
    id: 3,
    title: "Deluxe Rooms",
    subTitle: "Indulge in opulence and embrace the epitome of luxury.",
    cost: "15000",
    duration: "Approx 2 night trip",
    video: deluxeac,
  },
];
export default data;
