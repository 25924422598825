import React from "react";
import "./about.css";
import Image1 from "../assets1/26.jpg";
import { useNavigate } from "react-router-dom";
function About() {
  const navigate = useNavigate();
  return (
    <section className="" id="about">
      <div class="responsive-container-block bigContainer">
        <div class="responsive-container-block Container bottomContainer">
          <div class="ultimateImg">
            <img class="mainImg" src={Image1} />
            {/* <div class="purpleBox">
              <p class="purpleText">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                purus lectus viverra in semper nec pretium mus.
              </p>
              <img
                class="stars"
                src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg"
              />
            </div> */}
          </div>
          <div class="allText bottomText">
            <p class="text-blk headingText">ABOUT US</p>
            <p class="text-blk subHeadingText">
              Chariot Lodges are located at scenic mountains of Khanaspur between
              Murree, Abbottabad and Thandiani.
            </p>
            <p class="text-blk description">
              Nestled in the thick pine forest at a height of 7382 feet above
              sea level, the Chariot Lodges is a glorious resort. It offers
              extraordinary view of the valley and snow clad mountains during
              winters. Huts offer spacious rooms of various categories
              (Executive Suites, Deluxe and Standard Rooms) with direct access
              to garden & balcony. Our rooms and suites are furnished with
              modern facilities and décor, providing good range of modern
              amenities to ensure comfort and relaxing stay. We will ensure you
              a hassle-free stay while providing you comforts that you expect
              away from home.
            </p>
            <a
              onClick={() => {
                navigate("/contact");
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Use 'auto' for instant scroll
                });
              }}
            >
              <button className="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 120 120"
                  id="phone"
                >
                  <defs>
                    <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
                      <stop offset="0%" stopColor="#91FC8A" />
                      <stop offset="100%" stopColor="#00DD35" />
                    </linearGradient>
                  </defs>
                  <rect
                    width="120"
                    height="120"
                    x="0"
                    y="0"
                    rx="28"
                    fill="url(#b)"
                  />
                  <path
                    fill="#FFF"
                    d="M56 47.5v13H46c-6.09 0-9.5 1.5-10.5 9.5 0 2-.5 5-3.5 6l-16 3c-5 .5-6.453-.504-7.5-3C2.005 60.511 18 48 56 47.5zm0 0c38 .5 53.995 13.011 47.5 28.5-1.047 2.496-2.5 3.5-7.5 3l-16-3c-3-1-3.5-4-3.5-6-1-8-4.41-9.5-10.5-9.5H56v-13z"
                    transform="rotate(-135 56 63.312)"
                  />
                </svg>
                <p className="text">Book-Now</p>
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
