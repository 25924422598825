import React, { useRef } from "react";
import "./contact.css";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import URL from "../url";

function Contact() {
  const notyf = new Notyf({
    duration: 1000,
    position: {
      x: "center",
      y: "top",
    },
    types: [
      {
        type: "success",
        background: " green",
        duration: 2000,
        dismissible: true,
      },
      {
        type: "error",
        background: " rgb(27, 56, 82)",
        duration: 2000,
        dismissible: true,
      },
    ],
  });

  const roomref = useRef(null);
  const noroomref = useRef(null);
  const adultref = useRef(null);
  const childref = useRef(null);
  const infantref = useRef(null);
  const arrivalDateref = useRef(null);
  const departureDateref = useRef(null);
  const messageref = useRef(null);
  const nameref = useRef(null);
  const phoneref = useRef(null);
  const emailref = useRef(null);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  React.useEffect(() => {
    document.title = "Booking - CHARIOT LODGES";
  }, []);

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (roomref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select a room type!");
    } else if (noroomref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select no of rooms!");
    } else if (arrivalDateref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select your arrival date!");
    } else if (departureDateref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please select your departure date!");
    } else if (nameref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please type your name!");
    } else if (phoneref.current.value == "") {
      notyf.dismissAll();
      notyf.error("Please provide your phone no!");
    } else if (
      emailref.current.value !== "" &&
      !validateEmail(emailref.current.value)
    ) {
      notyf.dismissAll();
      notyf.error("Please provide a valid email!");
    } else {
      notyf.dismissAll();

      const url = `${URL}/availablerooms?type=${roomref.current.value}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        const availableRooms = data.available;
        if (
          parseInt(noroomref.current.value, 10) > parseInt(availableRooms, 10)
        ) {
          notyf.dismissAll();
          notyf.error(
            `Only ${availableRooms} ${roomref.current.value} available`
          );
        } else {
          const [yy, mm, dd] = arrivalDateref.current.value.split("-");
          const [yy1, mm1, dd1] = departureDateref.current.value.split("-");

          const roomNo = noroomref.current.value;
          const roomType = roomref.current.value;
          /*data*/
          const arrival = `${dd}-${mm}-${yy}`;
          const departure = `${dd1}-${mm1}-${yy1}`;
          const name = nameref.current.value;
          const phone = phoneref.current.value;
          const email = emailref.current.value;

          const roomData = roomNo + " " + roomType;
          const message = messageref.current.value;
          try {
            const response = await fetch(`${URL}/addBookingRequest`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                arg1: name,
                arg2: phone,
                arg3: email.length > 0 ? email : "none",
                arg4: roomData,
                arg5: arrival,
                arg6: departure,
                arg7: message,
              }),
            });

            if (response.ok) {
              const updatedObject = await response.json();
              notyf.dismissAll();
              notyf.success(
                "Your request has been received,you'll get a call shortly!"
              );
              const whatsappLink = `https://wa.me/+923141979327?text=Please check my booking status, request initiated with the name of ${name}`;
              // Open WhatsApp using the constructed link
              setTimeout(() => {
                window.open(whatsappLink, "_blank");
              }, 2000);
            } else {
              notyf.dismissAll();
              notyf.error("Request Failed, Try again");
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      } catch (error) {
        alert("not working");
        console.error("Error fetching available rooms:", error);
        return;
      }
    }
  };

  return (
    <section className="contact__section" id="contact">
      <div className="main_div">
        <div className="booking-form-w3layouts">
          <form>
            <h2 className="sub-heading-agileits">Booking Details</h2>
            <div className="main-flex-w3ls-sectns">
              <div className="field-agileinfo-spc form-w3-agile-text1">
                <select ref={roomref} className="form-control" required>
                  <option value="" disabled selected>
                    Room Type
                  </option>
                  <option value="Standard Room">Standard Room</option>
                  <option value="Executive Room">Executive Room</option>
                  <option value="Deluxe Room">Deluxe Room</option>

                  {/* <option value="Adipiscing">Adipiscing</option>
										<option value="Lorem Ipsum">Lorem Ipsum</option>
										<option value="Adipiscing">Adipiscing</option> */}
                </select>
              </div>
              <div className="field-agileinfo-spc form-w3-agile-text2">
                <select ref={noroomref} className="form-control" required>
                  <option value="" disabled selected>
                    Number of rooms
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                </select>
              </div>
            </div>

            <div className="triple-wthree">
              <div className="field-agileinfo-spc form-w3-agile-text11">
                <select ref={adultref} required className="form-control">
                  <option disabled selected value="">
                    Adult(14+ Yrs) Optional
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5+</option>
                </select>
              </div>
              <div className="field-agileinfo-spc form-w3-agile-text22">
                <select ref={childref} required className="form-control">
                  <option disabled selected value="">
                    Children(2-14 Yrs) Optional
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5+</option>
                </select>
              </div>
              <div className="field-agileinfo-spc form-w3-agile-text33">
                <select ref={infantref} required className="form-control">
                  <option disabled selected value="">
                    Infant(under 2Yrs) Optional
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5+</option>
                </select>
              </div>
            </div>

            <div className="arrivaldiv field-agileinfo-spc form-w3-agile-text33">
              <h2 className="sub-heading-agileits h11">
                Arrival Date &nbsp;&nbsp;&nbsp;&nbsp;
              </h2>
              <input
                ref={arrivalDateref}
                required
                type="date"
                id="arrival-date"
                className="date-input"
                placeholder="Select start date"
              />
            </div>
            <div className="arrivaldiv field-agileinfo-spc form-w3-agile-text33">
              <h2 className="sub-heading-agileits h11">Departure Date</h2>
              <input
                ref={departureDateref}
                required
                type="date"
                id="departure-date"
                className="date-input"
                placeholder="Select departure date"
              />
            </div>
            <div className="field-agileinfo-spc form-w3-agile-text">
              <textarea
                ref={messageref}
                name="Message"
                placeholder="Any message"
              ></textarea>
            </div>
            <h3 className="sub-heading-agileits">Personal Details</h3>
            <div className="main-flex-w3ls-sectns">
              <div className="field-agileinfo-spc form-w3-agile-text1">
                <input
                  ref={nameref}
                  type="text"
                  name="Name"
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="field-agileinfo-spc form-w3-agile-text2">
                <input
                  ref={phoneref}
                  type="text"
                  name="Phone no"
                  placeholder="Phone Number"
                  required
                />
              </div>
            </div>
            <div className="field-agileinfo-spc form-w3-agile-text">
              <input
                ref={emailref}
                type="email"
                name="Email"
                placeholder="Email"
              />
            </div>
            <div className="clear"></div>
            <input type="submit" value="Submit" onClick={SubmitForm} />
            <input type="reset" value="Clear Form" />
            <div className="clear"></div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
